import "react-web-tabs/dist/react-web-tabs.css";

import React, { useEffect, useRef, useState } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";
import {
  Onboarding_November,
  OnboardingMobile_November,
  usePreloadImages_November,
} from "../conversational-ai-platform";
import ReactBootstrapCarousel from "react-bootstrap-carousel";
import { Swipeable, useSwipeable } from "react-swipeable";
import { Tab, TabList, TabPanel, TabProvider, Tabs } from "react-web-tabs";
import { useMedia } from "use-media";

import RegisterSection from "../../components/chat-channel/ChannelHeader";
import Container from "../../components/chat-channel/Container";
import Layout from "../../components/chat-channel/Layout";
import { FeatureArrowWithColor } from "../../components/common/Icons";
import TitleAndMetaTags from "../../components/common/TitleAndHeader";
import { RequestForm } from "../../components/form";
import { VideoElement2 } from "../../components/video";
import { OnboardingSlider } from "../conversational-ai-platform";
import { OnboardingMobile } from "../conversational-ai-platform";
import { GoToPopup } from "../homepage";
import { CookiesPoup } from "../homepage";

const reviewer_image = require("../../assets/images/testimonials/review_hammer.png");
const hammer_logo = require("../../assets/images/testimonials/hammer_logo.svg");
const star_image = require("../../assets/images/testimonials/star.svg");
const capterra_logo = require("../../assets/images/testimonials/capterra_logo.svg");
const g2_logo = require("../../assets/images/testimonials/g2_logo.svg");
const quotes = require("../../assets/images/testimonials/quotes.svg");

const VidImg = require("../../assets/img/integration/bamboohr/m_power_video.png");

const topImage = require("../../assets/img/integration/bamboohr/ibm_altrn_header.png");
const ibmTick = require("../../assets/images/onboarding/onboarding_tick.svg");

const slider_1 = require("../../assets/img/home_page_assist/ibm_watson_slider_1.png");
const slider_2 = require("../../assets/img/home_page_assist/ibm_watson_slider_2.png");
const slider_3 = require("../../assets/img/home_page_assist/ibm_watson_slider_3.png");
const slider_4 = require("../../assets/img/home_page_assist/ibm_watson_slider_4.png");

const section_one = require("../../assets/img/home_page_assist/ibm_watson_sec_1.png");
const section_two = require("../../assets/img/home_page_assist/ibm_watson_sec_2.png");
const section_three = require("../../assets/img/home_page_assist/ibm_watson_sec_3.png");
const section_four = require("../../assets/img/home_page_assist/ibm_watson_sec_4.png");

const access_management = require("../../assets/landing_servicenow/ITC_02_access_menagement_IT_chatbot.png");
const asset_requests = require("../../assets/landing_servicenow/ITC_02_asset_request_IT_chatbot.png");
const password_resets = require("../../assets/landing_servicenow/ITC_02_reset_password_IT_chatbot.png");
const unlock_account = require("../../assets/landing_servicenow/ITC_02_unlock_account_IT_chatbot.png");
const user_provisioning = require("../../assets/landing_servicenow/ITC_02_user_provisioning_IT_chatbot.png");
const offboarding = require("../../assets/landing_servicenow/ITC_02_offboarding_IT_chatbot.png");
const onboarding = require("../../assets/landing_servicenow/ITC_02_onboarding_IT_chatbot.png");

const customerBanner = require("../../assets/img/feature/home/header_customer_banner.svg");
const bannerImageMobile = require("../../assets/img/feature/home/header_customer_banner_mobile.svg");

const FeatureSlack = require("../../assets/images/features_images/features_slack.png");
const FeatureTeams = require("../../assets/images/features_images/features_teams.png");
const FeatureChat = require("../../assets/images/features_images/features_chat.png");

const OnboardSlide = require("../../assets/img/home_page_assist/4x_itsm_onboarding_1.png");
const OnboardSlide1 = require("../../assets/img/home_page_assist/4x_itsm_onboarding_2.png");
const OnboardSlide2 = require("../../assets/img/home_page_assist/4x_itsm_onboarding_3.png");
const OnboardSlide3 = require("../../assets/img/home_page_assist/4x_itsm_onboarding_4.png");
const OnboardSlide4 = require("../../assets/img/home_page_assist/4x_itsm_onboarding_5.png");
const OnboardArrow = require("../../assets/images/onboarding/onboarding_arrow.svg");

export default function FeatureHomePage() {
  const isSmall = useMedia({ maxWidth: "520px" });

  return (
    <>
      <TitleAndMetaTags
        title="Microsoft Power Virtual Agent Alternative - See MS Power Virtual Agent Vs Workativ Assistant"
        description="Workativ Assistant is the perfect alternative to Microsoft Power Virtual Agent. Support your employee better with our conversational ai chatbot + prebuilt app workflow"
        keywords={["Box "]}
        ogImage={topImage}
      />

      <section className="ibm-watson-assistants-main-container">
        <Container>
          <Layout backgroundColor={"landing_bg"} logoFor="ASSISTANT">
            <RegisterSection
              rightImage={topImage}
              backgroundColor={"landing_bg"}
              altImage={"Box Chatbot With Auto Resolutions"}
              iD={"chatbot"}
              additionalClassImage={"w-90"}
            >
              <RegisterSection.FormWithContent>
                <RegisterSection.MainHeader>
                  Looking for a Microsoft Power Virtual Agent alternative?
                </RegisterSection.MainHeader>
                <RegisterSection.SubHeader>
                  Microsoft Power Virtual Agent is a popular chatbot development
                  platform from Microsoft that uses natural language processing
                  to build chatbot for customer experience. If you’re
                  contemplating Microsoft Power Virtual Agent, you may also want
                  to look at alternatives to ensure you are getting most value
                  as other chatbot platforms offer better and or differentiated
                  capabilities.
                </RegisterSection.SubHeader>
              </RegisterSection.FormWithContent>
            </RegisterSection>

            <SwipeableTop />
            <Switcher />
            <TestimonialsHomePage />
            <FeaturesUsecases />
            <RequestForm isFooterForm={true} />

            <div class="container">
              <div className="build_needs">
                <div
                  class="col-lg-12 col-md-12 col-12 pl-0 center_features_header"
                  style="margin-bottom: 20px;"
                >
                  <h2 className="font-section-header">
                    Demo Video on building Power Virtual Agent Alternative
                  </h2>
                </div>
              </div>
            </div>
            <VideoElement2
              videoURL={"https://www.youtube.com/embed/DgftmY2RM9A"}
              imgURL={VidImg}
              imgMob={VidImg}
            />
            <section
              style={{ background: "#f8f8f8" }}
              className="width-100 float-left margin-bottom-40 onboard_customer_join_alternative_main"
            >
              <div className={`float-left w-100 ${isSmall ? "pt-4" : "pt-5"}`}>
                <div className="container mb-2">
                  <h5
                    className={`text-center font-section-normal-text-medium-customer-banner ${
                      isSmall ? "mb-3" : "margin-bottom-20 "
                    }`}
                  >
                    Join hundreds of industry leaders
                  </h5>{" "}
                  {isSmall ? (
                    <img className="" src={bannerImageMobile} />
                  ) : (
                    <img className="  d-flex m-auto" src={customerBanner} />
                  )}
                </div>
              </div>
              {isSmall ? (
                <OnboardingMobile_November
                  tabContent={tabsForOnBoarding}
                  imagesToPreload={tabsForOnBoardingImages}
                />
              ) : (
                <Onboarding_November
                  tabContent={tabsForOnBoarding}
                  imagesToPreload={tabsForOnBoardingImages}
                />
              )}
            </section>

            <Faq />
            <CardFour />
            {/* <GoToPopup />
            <CookiesPoup /> */}
            <RequestForm isFooterForm={true} />
          </Layout>
        </Container>
      </section>
    </>
  );
}
function TestimonialsHomePage() {
  const isSmall = useMedia({ maxWidth: "820px" });
  return isSmall ? (
    <section class="testimonial_background_main_container_mobile float-left">
      <div class="container">
        <div class="row">
          <div className="testimonial_feedback_box_mobile">
            <div className="testimonial_feedback_top_content_box_mobile">
              <p className="font-section-normal-text">
                <span>
                  <img src={quotes} className="quotes_image_testimonial"></img>
                </span>
                The setup and building of the Workativ Chatbot I felt was
                extremely well supported! My questions were answered quickly and
                at no point did I feel unsupported. I had multiple Zoom meetings
                through the process where we would meet to go through the build.
              </p>
              <p className="font-section-normal-text">
                I felt listened through what we absolutely had to have for this
                tool to be rolled out in our environment. Throughout the process
                we kept looking back at the business requirements to make sure
                we were aligning properly.
              </p>
            </div>
            <div className="testimonial_review_bottom_image_box_mobile">
              <div className="bottom_image_box_left">
                <img
                  src={reviewer_image}
                  className="testimonial_reviewer_image"
                ></img>
              </div>
              <div className="bottom_designation_box_right_mobile">
                <h5
                  className="font-section-sub-header"
                  style={{ color: "#526CB1" }}
                >
                  Dan Bottiger
                </h5>
                <h5 className="font-section-normal-text-medium reviewer_designation_hammer">
                  Director of Information Systems
                </h5>
                <img
                  src={hammer_logo}
                  className="testimonial_hammer_logo_mobile"
                ></img>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  ) : (
    <section class="testimonial_background_main_container float-left">
      <div className="container">
        <div className="row">
          <div className="testimonial_feedback_box">
            <div className="testimonial_feedback_left_image_box">
              <img
                src={reviewer_image}
                className="testimonial_reviewer_image"
              ></img>
            </div>
            <div className="testimonial_feedback_right_content_box">
              <p className="font-section-normal-text line-height-2">
                <span>
                  <img src={quotes} className="quotes_image_testimonial"></img>
                </span>
                The setup and building of the Workativ Chatbot I felt was
                extremely well supported! My questions were answered quickly and
                at no point did I feel unsupported. I had multiple Zoom meetings
                through the process where we would meet to go through the build.
                When the build hit a hurdle, the resolution was found and
                implemented within 24-48 hours. Through this communication was
                excellent.
              </p>
              <p className="font-section-normal-text line-height-2">
                {" "}
                I felt listened through what we absolutely had to have for this
                tool to be rolled out in our environment. Throughout the process
                we kept looking back at the business requirements to make sure
                we were aligning properly.
              </p>
              <div className="designation_and_hammer_logo">
                <div>
                  <h5
                    className="font-section-sub-header"
                    style={{ color: "#526CB1" }}
                  >
                    Dan Bottiger
                  </h5>
                  <h5 className="font-section-normal-text-medium reviewer_designation_hammer">
                    Director of Information Systems
                  </h5>
                </div>
                <img
                  src={hammer_logo}
                  className="testimonial_hammer_logo"
                ></img>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
function RatingHomePage() {
  const isSmall = useMedia({ maxWidth: "520px" });
  return (
    <section class="testimonial_background_main_container testimonial_background_rating_padding_mobile float-left">
      <div className="container">
        <div className="row">
          <div className="rating_box_main_container">
            <div className="rating_left_box">
              <div className="rating_star_box">
                <img src={star_image} className="rating_star_image"></img>
                <img src={star_image} className="rating_star_image"></img>
                <img src={star_image} className="rating_star_image"></img>
                <img src={star_image} className="rating_star_image"></img>
                <img src={star_image} className="rating_star_image"></img>
                <span>5/5</span>
              </div>
              <p className="font-section-normal-text rating_paragraph_content line-height-2">
                Very positive experience. We are able to grow the bot based on
                our internal needs. The cost and support can’t be beat for all
                the features the tool provides.
              </p>

              <img className="capterra_logo_left" src={capterra_logo} />
            </div>
            <div className="rating_right_box">
              <div className="rating_star_box">
                <img src={star_image} className="rating_star_image"></img>
                <img src={star_image} className="rating_star_image"></img>
                <img src={star_image} className="rating_star_image"></img>
                <img src={star_image} className="rating_star_image"></img>
                <img src={star_image} className="rating_star_image"></img>
                <span>5/5</span>
              </div>
              <p className="font-section-normal-text rating_paragraph_content line-height-2">
                The deployment and support team was exceptional! They worked
                hard to make sure our rollout was successful.
              </p>
              <img src={g2_logo} className="g2_logo_right"></img>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
function SwipeableTop() {
  const isSmall = useMedia({ maxWidth: "520px" });
  const [activeSlider, setActiveSlider] = useState(0);
  const slider = useRef(null);
  const handlers = useSwipeable({
    onSwipedLeft: () => {
      console.log("swiped left");
      slider.current.slideNext();
    },
    onSwipedRight: () => {
      // console.log("decrementOffset")
      console.log("swiped right");
      slider.current.slidePrev();
    },
    preventDefaultTouchmoveEvent: false,
    trackMouse: true,
  });

  const _slidePrev = () => {
    console.log("slider.current", slider.current);
    slider.current.slidePrev();
  };

  const _slideNext = () => {
    slider.current.slideNext();
  };

  const _goToSlide = (slideNo) => {
    slider.current.goToSlide(slideNo - 1);
  };

  const onSelect = (item) => {
    setActiveSlider(item);
  };

  return (
    <section className="float-left ibm-watson-top-second-section">
      <div className="container">
        <h2 className="font-section-sub-header" style="text-align: center;">
          Introducing Microsoft Power Virtual Agent alternative
        </h2>
        <p className="font-section-normal-text" style="text-align: center;">
          Workativ is a FREE no-code SaaS platform for companies too build AI
          chatbot with app workflow automation in minutes. Workativ is purpose
          designed to address HR and IT support use case.
        </p>
        <div
          className={`col-lg-12 col-md-12 col-sm-12 col-xs-12 features_change_img_header chat_img_header ${
            isSmall ? "" : "pl-0"
          }`}
        >
          <div className="features_change_img desktop_slider_cc">
            <div
              className="chat-channel assistant_change_img feature_cc_slider mb-0 width-100"
              {...handlers}
            >
              <ReactBootstrapCarousel
                animation={true}
                autoplay={true}
                slideshowSpeed={7000}
                defaultActiveIndex={0}
                indicators={false}
                onSelect={onSelect}
                ref={slider}
                version={4}
              >
                <div className="corousel-image-center">
                  <img
                    loading="lazy"
                    className="d-block mb-0 padding-0-imp shadow-none automate-swipe-image-home"
                    src={slider_1}
                    alt="chatbot channel integration"
                  />
                </div>
                <div className="corousel-image-center">
                  <img
                    loading="lazy"
                    className="d-block  mb-0 padding-0-imp shadow-none automate-swipe-image-home"
                    src={slider_2}
                    alt="chatbot channel integration"
                  />
                </div>
                <div className="corousel-image-center">
                  <img
                    loading="lazy"
                    className="d-block  mb-0 padding-0-imp shadow-none automate-swipe-image-home"
                    src={slider_3}
                    alt="chatbot channel integration"
                  />
                </div>
                <div className="corousel-image-center">
                  <img
                    loading="lazy"
                    className="d-block  mb-0 padding-0-imp shadow-none automate-swipe-image-home"
                    src={slider_4}
                    alt="chatbot channel integration"
                  />
                </div>
              </ReactBootstrapCarousel>
              <div className="row padding-top-bottom-20">
                <div className="swipe-chat-channel">
                  <div
                    className={
                      activeSlider !== 0 ? `swipe-one active` : "swipe-one"
                    }
                    tabindex="1"
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      if (activeSlider !== 0) {
                        _slidePrev();
                      }
                    }}
                  >
                    <FeatureArrowWithColor
                      fillColor={activeSlider !== 0 ? "#2468F6" : "#B7B7B7"}
                      className="swipe-icon ar-left rotate-180"
                    />
                  </div>
                  <div
                    className={
                      activeSlider === 0 ? `swipe-one active` : "swipe-one"
                    }
                    onClick={(e) => {
                      _goToSlide(1);
                      e.stopPropagation();
                      e.preventDefault();
                    }}
                  >
                    <span></span>
                  </div>
                  <div
                    className={
                      activeSlider === 1 ? `swipe-one active` : "swipe-one"
                    }
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      _goToSlide(2);
                    }}
                  >
                    <span></span>
                  </div>
                  <div
                    className={
                      activeSlider === 2 ? `swipe-one active` : "swipe-one"
                    }
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      _goToSlide(3);
                    }}
                  >
                    <span></span>
                  </div>
                  <div
                    className={
                      activeSlider === 3 ? `swipe-one active` : "swipe-one"
                    }
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      _goToSlide(4);
                    }}
                  >
                    <span></span>
                  </div>
                  <div
                    className={
                      activeSlider !== 3 ? `swipe-one active` : "swipe-one"
                    }
                    tabindex="2"
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      if (activeSlider !== 3) {
                        _slideNext();
                      }
                    }}
                  >
                    <FeatureArrowWithColor
                      fillColor={activeSlider !== 3 ? "#2468F6" : "#B7B7B7"}
                      className="swipe-icon ar-right"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
function MobileTabs(props) {
  const [p, setP] = useState(0);
  const [finished, setFinished] = useState([0]);
  const intervalRef = useRef(null);

  const additionalContainerClass = props.additionalContainerClass;
  const onboardingSliderComponents = props.sections;
  const additionalClass = props.additionalClass;

  // console.log("MobileTabs :::: ", onboardingSliderComponents);

  const Step = onboardingSliderComponents[p]
    ? () => onboardingSliderComponents[p].tab
    : () => null;

  console.log("MobileTabs :::: 2", onboardingSliderComponents[p], Step);
  function update() {
    var element = document.getElementById(`stepprogressBar${p}`);
    var width = 1;
    intervalRef.current = setInterval(scene, 100);
    var element1 = document.getElementById(`stepprogressBar${0}`);
    var element2 = document.getElementById(`stepprogressBar${1}`);
    var element3 = document.getElementById(`stepprogressBar${2}`);
    var element4 = document.getElementById(`stepprogressBar${3}`);

    function scene() {
      if (width >= 100) {
        if (p == 3) {
          if (element1) element1.style.width = "0%";
          if (element2) element2.style.width = "0%";
          if (element3) element3.style.width = "0%";
          if (element4) element4.style.width = "0%";
        }

        clearInterval(intervalRef.current);
        setFinished(p == 3 ? [0] : [...finished, p + 1]);
        setP(p < 3 ? p + 1 : 0);
      } else {
        width++;
        if (p < 3) {
          if (element) {
            element.style.width = width + "%";
          }
        }
      }
    }
  }

  useEffect(() => {
    // setTimeout(() => {
    //   update();
    // });

    return () => {
      intervalRef.current && clearInterval(intervalRef.current);
    };
  }, [p]);

  function clickHandling(index) {
    const baseProg = [0, 1, 2, 3];
    clearInterval(intervalRef.current);
    baseProg
      .filter((i) => i > index && i != 3)
      .reduce((acc, val) => {
        var element = document.getElementById(`stepprogressBar${val}`);
        element.style.width = "0%";

        return acc;
      }, 0);

    baseProg
      .filter((i) => (index == 0 ? i <= index : i < index))
      .reduce((acc, val) => {
        var element = document.getElementById(`stepprogressBar${val}`);
        element.style.width = "100%";

        return acc;
      }, 0);
    setFinished(baseProg.filter((i) => i <= index));
    setP(index);
  }

  function nextOrPrevSlide(type) {
    const baseProg = [0, 1, 2, 3];
    clearInterval(intervalRef.current);
    setFinished(baseProg.filter((i) => i <= (type == "next" ? p + 1 : p - 1)));
    setP(type == "next" ? p + 1 : p - 1);
  }

  return (
    <section
      className={`width-100-percent  ${
        additionalContainerClass ? additionalContainerClass : ""
      }`}
    >
      <div>
        <div
          className={`${"onboarding_mobile_progress"} ${
            additionalClass ? additionalClass : ""
          }`}
        >
          <div
            className={
              p != 0
                ? "onboarding_mob_arrow_right rotate-180"
                : "onboarding_mob_arrow_left rotate-180"
            }
            onClick={() => p != 0 && nextOrPrevSlide("prev")}
          >
            &#10132;
          </div>

          <h5 className="mobile_step_header ">
            {" "}
            <Step />
          </h5>
          <div
            className={
              p != 3
                ? "onboarding_mob_arrow_right"
                : "onboarding_mob_arrow_left"
            }
            onClick={() => p != 3 && nextOrPrevSlide("next")}
          >
            &#10132;
          </div>
        </div>
        {onboardingSliderComponents.map((slid, i) =>
          i == p ? slid.section : null
        )}
      </div>
    </section>
  );
}
function Switcher() {
  const isSmall = useMedia({ maxWidth: "520px" });
  const [offsetTopTabs, setOffsetTopTabs] = React.useState(0);

  function incrementTopOffset() {
    if (offsetTopTabs === total - 1) {
      setOffsetTopTabs(loop ? 0 : offsetTopTabs);
    } else {
      // console.log("setOffset", offset)
      setOffsetTopTabs(offsetTopTabs + 1);
    }
  }

  function decrementTopOffset() {
    if (offsetTopTabs === 0) {
      setOffsetTopTabs(loop ? total - 1 : offsetTopTabs);
    } else {
      setOffsetTopTabs(offsetTopTabs - 1);
    }
  }
  const handlersTop = useSwipeable({
    onSwipedLeft: () => {
      // console.log("decrementOffset")
      incrementTopOffset();
    },
    onSwipedRight: () => {
      // console.log("decrementOffset")
      decrementTopOffset();
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });
  const total = slides.length; // the length of the slide array
  const enabled = true; // pauses/disables the player
  const useLoaded = false; // this allows for delayed loads like images or embeds
  const speed = null; // speed in milliseconds to show each slide
  const loop = true;

  return (
    <section className="float-left ibm-watson-tab-section">
      <div className="container">
        <div>
          <h2 className="font-section-sub-header" style="text-align: center;">
            What makes Workativ different from Microsoft Power Virtual Agent?
          </h2>
          <p className="font-section-normal-text" style="text-align: center;">
            Workativ delivers more value for money than Microsoft Power Virtual
            Agent
          </p>
        </div>
        {isSmall ? (
          <MobileTabs
            sections={mobileTablsTopData.sections}
            additionalClass="flex_no_wrap_ibm_tab"
          />
        ) : (
          <TabProvider
            defaultTab={isSmall ? `section${offsetTopTabs + 1}` : "section1"}
            vertical
          >
            <div className="int_landing_page" {...handlersTop}>
              <TabList className="horizontal_slider">
                <Tab offset={slides[offsetTopTabs + 0]} tabFor="section1">
                  1. Simplified UX
                </Tab>

                <Tab offset={slides[offsetTopTabs + 1]} tabFor="section2">
                  2. Built-in App Workflows
                </Tab>

                <Tab offset={slides[offsetTopTabs + 2]} tabFor="section3">
                  3. Bots & App Integrations Marketplace
                </Tab>

                <Tab offset={slides[offsetTopTabs + 3]} tabFor="section4">
                  4. Live Agent Handover
                </Tab>
              </TabList>

              <TabPanel offset={slides[offsetTopTabs + 0]} tabId="section1">
                <Section1 />
              </TabPanel>
              <TabPanel offset={slides[offsetTopTabs + 1]} tabId="section2">
                <Section2 />
              </TabPanel>
              <TabPanel offset={slides[offsetTopTabs + 2]} tabId="section3">
                <Section3 />
              </TabPanel>
              <TabPanel offset={slides[offsetTopTabs + 3]} tabId="section4">
                <Section4 />
              </TabPanel>
            </div>
          </TabProvider>
        )}
      </div>
    </section>
  );
}
function FeaturesUsecases() {
  return (
    <section className="features_usecases_ibm_container float-left">
      <div className="container">
        <h2
          className="font-section-sub-header mb-4"
          style="text-align: center;"
        >
          Other amazing features & user cases of Workativ include
        </h2>
        <div className="features_usecases_ibm_sub_container">
          <ul className="list-ibm-usecases">
            <h5 className="font-section-sub-header-small">Features:</h5>
            <li>
              {" "}
              <img loading="lazy" src={ibmTick} />{" "}
              <span className="font-section-normal-text">chatbot Builder</span>
            </li>
            <li>
              {" "}
              <img loading="lazy" src={ibmTick} />{" "}
              <span className="font-section-normal-text">
                App Workflow Builder
              </span>
            </li>
            <li>
              {" "}
              <img loading="lazy" src={ibmTick} />{" "}
              <span className="font-section-normal-text">FAQ Builder</span>
            </li>
            <li>
              {" "}
              <img loading="lazy" src={ibmTick} />{" "}
              <span className="font-section-normal-text">
                Create Multiple Bots
              </span>
            </li>
            <li>
              {" "}
              <img loading="lazy" src={ibmTick} />{" "}
              <span className="font-section-normal-text">
                Multi-Step App Workflows
              </span>
            </li>
            <li>
              {" "}
              <img loading="lazy" src={ibmTick} />{" "}
              <span className="font-section-normal-text">
                Bot Marketplace (Pre-built Bots & App Workflows)
              </span>
            </li>
            <li>
              {" "}
              <img loading="lazy" src={ibmTick} />{" "}
              <span className="font-section-normal-text">
                Bot training, testing, logs, performance dashboard
              </span>
            </li>
            <li>
              {" "}
              <img loading="lazy" src={ibmTick} />{" "}
              <span className="font-section-normal-text">
                Approval Management
              </span>
            </li>
            <li>
              {" "}
              <img loading="lazy" src={ibmTick} />{" "}
              <span className="font-section-normal-text">
                On-prem App Connector
              </span>
            </li>
            <li>
              {" "}
              <img loading="lazy" src={ibmTick} />{" "}
              <span className="font-section-normal-text">
                Chat Channel Integration(eg.,Teams,Slack,etc)
              </span>
            </li>
            <li>
              {" "}
              <img loading="lazy" src={ibmTick} />{" "}
              <span className="font-section-normal-text">Chat Widget</span>
            </li>
            <li>
              {" "}
              <img loading="lazy" src={ibmTick} />{" "}
              <span className="font-section-normal-text">
                Live Agent Handover(e.g.,Teams){" "}
              </span>
            </li>
            <li>
              {" "}
              <img loading="lazy" src={ibmTick} />{" "}
              <span className="font-section-normal-text">
                Bot fully customizable using No-Code.
              </span>
            </li>
            <li>
              {" "}
              <img loading="lazy" src={ibmTick} />{" "}
              <span className="font-section-normal-text">
                Digital Onboarding
              </span>
            </li>
            <li>
              {" "}
              <img loading="lazy" src={ibmTick} />{" "}
              <span className="font-section-normal-text">
                70+ App Integrations
              </span>
            </li>
            <li>
              {" "}
              <img loading="lazy" src={ibmTick} />{" "}
              <span className="font-section-normal-text">
                600+ App Workflows
              </span>
            </li>
          </ul>
          <ul className="list-ibm-usecases">
            <h5 className="font-section-sub-header-small">Use cases:</h5>
            <li>
              {" "}
              <img loading="lazy" src={ibmTick} />{" "}
              <span className="font-section-normal-text">
                Resetting passwords
              </span>
            </li>
            <li>
              {" "}
              <img loading="lazy" src={ibmTick} />{" "}
              <span className="font-section-normal-text">
                Unlocking accounts
              </span>
            </li>
            <li>
              {" "}
              <img loading="lazy" src={ibmTick} />{" "}
              <span className="font-section-normal-text">
                Adding users to distribution groups
              </span>
            </li>
            <li>
              {" "}
              <img loading="lazy" src={ibmTick} />{" "}
              <span className="font-section-normal-text">
                Locking devices remotely
              </span>
            </li>
            <li>
              {" "}
              <img loading="lazy" src={ibmTick} />{" "}
              <span className="font-section-normal-text">
                Installing application on the devices
              </span>
            </li>
            <li>
              {" "}
              <img loading="lazy" src={ibmTick} />{" "}
              <span className="font-section-normal-text">
                Wiping the device data
              </span>
            </li>
            <li>
              {" "}
              <img loading="lazy" src={ibmTick} />{" "}
              <span className="font-section-normal-text">
                Applying for leave
              </span>
            </li>
            <li>
              {" "}
              <img loading="lazy" src={ibmTick} />{" "}
              <span className="font-section-normal-text">
                Adding a dependent
              </span>
            </li>
            <li>
              {" "}
              <img loading="lazy" src={ibmTick} />{" "}
              <span className="font-section-normal-text">
                Amswering common queries related to HR, Payroll, & leaves
              </span>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
}
function Faq() {
  return (
    <React.Fragment>
      <div className="mob_accordian mob_accordian_faq ibm_watson_faq_container">
        <section className="accordian_landing trial_accordian_landing">
          <div className="container">
            <h2 className="font-section-sub-header">
              Workativ progressed beyond a simple chatbot and go beyond
              Microsoft Power Virtual Agent capabilities
            </h2>
            <div className="col-12 pl-0 faq_landing">
              <Accordion
                id="accordion"
                className="accordion pl-0"
                preExpanded={["a"]}
              >
                <div className="accordion-wrapper pricing_faq">
                  {faqData.map((data) => (
                    <AccordionItem uuid={data.uuid}>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          <h3 className="font-section-sub-header-small">
                            {data.header}
                          </h3>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p
                          dangerouslySetInnerHTML={{ __html: data.paragraph }}
                        />
                        {data.paragraph1 && (
                          <p
                            className="para_2_pricing"
                            dangerouslySetInnerHTML={{
                              __html: data.paragraph1,
                            }}
                          />
                        )}
                      </AccordionItemPanel>
                    </AccordionItem>
                  ))}
                </div>
              </Accordion>
            </div>
          </div>
        </section>
      </div>
    </React.Fragment>
  );
}
function CardFour() {
  return (
    <section className="float-left " style="width: 100%; margin-bottom:46px">
      <div className="container">
        <h2
          className="font-section-sub-header mb-4"
          style="text-align: center;"
        >
          Workativ provides a cost effective alternative to Microsoft Power
          Virtual Agent
        </h2>
        <div className="card-ibm-watson-assistant">
          <div className="card-one-ibm">
            <p class="font-section-normal-text mb-0 pb-0">
              Workativ Assistant’s key features include the ability for
              businesses to quickly develop, deploy, and train Virtual
              Assistants using a simple, easy-to-use visual interface.
            </p>
          </div>
          <div className="card-two-ibm">
            <p class="font-section-normal-text mb-0 pb-0">
              It includes a comprehensive App workflow builder that allows you
              to develop, create, and execute complex IT processes.
            </p>
          </div>
          <div className="card-three-ibm">
            <p class="font-section-normal-text mb-0 pb-0">
              Virtual assistants can be easily implemented on well-known
              conversational channels like Teams, Slack, SharePoint sites, or
              even self-service portals.
            </p>
          </div>
          <div className="card-four-ibm">
            <p class="font-section-normal-text mb-0 pb-0">
              Workativ Assistant provides great flexibility to IT teams, HRMs,
              and other sectors by automating time-consuming processes and
              allowing end-users to access suitable workplace help from anywhere
              and at any time.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
const tabsForOnBoarding = [
  {
    header: "Sign up",
    hasGridLayoutBigger: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="onboard_content_left_container">
          <h3 className="font-section-normal-text-medium-link">Sign up</h3>
          <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
            Create a new bot or download pre-built bots from marketplace
          </p>
        </div>
        <div className="onboard_content_right_container">
          <img src={OnboardSlide} alt="Sign up"></img>
        </div>{" "}
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        <h3 className="font-section-sub-header">Sign up</h3>
        <p className="font-section-normal-text-testimonials line-height-18 mb-0">
          Create a new bot or download pre-built bots from marketplace
        </p>
        <img src={OnboardSlide} alt="Sign up"></img>
      </>
    ),
  },
  {
    header: "Download Pre-built Bot",
    hasGridLayoutBigger: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="onboard_content_left_container">
          <h3 className="font-section-normal-text-medium-link">
            Bot Marketplace
          </h3>
          <p className="font-section-normal-text-testimonials line-height-18">
            Bot templates are pre-built bots with conversations covering most
            common and repetitive support topics.
          </p>
          <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
            Easily download bots in one-click, add new or edit existing support
            topics in minutes.
          </p>
        </div>
        <div className="onboard_content_right_container">
          <img src={OnboardSlide1} alt="Bot Marketplace"></img>
        </div>{" "}
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        <h3 className="font-section-sub-header"> Bot Marketplace</h3>
        <p className="font-section-normal-text-testimonials line-height-18">
          Bot templates are pre-built bots with conversations covering most
          common and repetitive support topics.
        </p>
        <p className="font-section-normal-text-testimonials line-height-18">
          Easily download bots in one-click, add new or edit existing support
          topics in minutes.
        </p>
        <img src={OnboardSlide1} alt="Bot Marketplace"></img>
      </>
    ),
  },
  {
    header: "Connect App Workflows",
    hasGridLayoutBigger: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="onboard_content_left_container">
          <h3 className="font-section-normal-text-medium-link">
            Connect App Workflows
          </h3>
          <p className="font-section-normal-text-testimonials line-height-18">
            Connecting chatbot with your apps and building app workflows
            requires a lot of development efforts, time, and costs.
          </p>
          <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
            But workativ provides pre-built app integrations and app workflows
            so you can connect your chatbot with your app and automate tasks
            instantly. No coding.
          </p>
        </div>
        <div className="onboard_content_right_container">
          <img src={OnboardSlide2} alt="Connect App Workflows"></img>
        </div>{" "}
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        <h3 className="font-section-sub-header">Connect App Workflows</h3>
        <p className="font-section-normal-text-testimonials line-height-18">
          Connecting chatbot with your apps and building app workflows requires
          a lot of development efforts, time, and costs.
        </p>
        <p className="font-section-normal-text-testimonials line-height-18">
          But workativ provides pre-built app integrations and app workflows so
          you can connect your chatbot with your app and automate tasks
          instantly. No coding.
        </p>

        <img src={OnboardSlide2} alt="Connect App Workflows"></img>
      </>
    ),
  },
  {
    header: "Test your Bot",
    hasGridLayoutBigger: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="onboard_content_left_container">
          <h3 className="font-section-normal-text-medium-link">Bot Testing</h3>
          <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
            Test your bot in real time using “Try Me”. Make adjustments, add or
            edit conversations, fine tune user interactions, and review your bot
            conversations on the go before you go-live.
          </p>
        </div>
        <div className="onboard_content_right_container">
          <img src={OnboardSlide3} alt="Test your Bot"></img>
        </div>{" "}
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        <h3 className="font-section-sub-header">Bot Testing</h3>
        <p className="font-section-normal-text-testimonials line-height-18">
          Test your bot in real time using “Try Me”. Make adjustments, add or
          edit conversations, fine tune user interactions, and review your bot
          conversations on the go before you go-live.
        </p>
        <img src={OnboardSlide3} alt="Test your Bot"></img>
      </>
    ),
  },
  {
    header: "Go Live",
    hasGridLayoutLittleLarge: true,
    hasGridLayoutHeight: true,
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="onboard_content_left_container pr-0">
          <h3 className="font-section-normal-text-medium-link">
            Deploy on Teams, Slack, or as Chat Widget
          </h3>
          <p className="font-section-normal-text-testimonials line-height-18">
            Turn your favorite collaboration app into modern employee
            self-service by deploying chatbot on your Teams, Slack or as Widget
            on your self-help portal in few clicks.
          </p>
          <p className="font-section-normal-text-testimonials line-height-18">
            Customize the end-user experience by applying your company branding,
            logo etc.
          </p>
          <div className="icons_container_onboarding">
            <div className="icon_text">
              <img
                loading="lazy"
                class="slack_feature_icon_onboarding"
                src={FeatureSlack}
              ></img>
              <h6 className="font-section-small-text-medium-apps color-light-black mb-0 pt-1">
                Slack
              </h6>
            </div>

            <div className="icon_text">
              <img
                loading="lazy"
                class="teams_feature_icon_onboarding"
                src={FeatureTeams}
              ></img>
              <h6 className="font-section-small-text-medium-apps color-light-black mb-0 pt-1">
                Teams
              </h6>
            </div>
            <div className="icon_text">
              <img
                loading="lazy"
                class="chat_feature_icon_onboarding"
                src={FeatureChat}
              ></img>
              <h6 className="font-section-small-text-medium-apps color-light-black mb-0 pt-1">
                Chat Widget
              </h6>
            </div>
          </div>
        </div>
        <div className="onboard_content_right_container">
          <img src={OnboardSlide4} alt="Go Live"></img>
        </div>{" "}
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        <h3 className="font-section-sub-header">
          Deploy on Teams, Slack, or as Chat Widget
        </h3>
        <p className="font-section-normal-text-testimonials line-height-18">
          Turn your favorite collaboration app into modern employee self-service
          by deploying chatbot on your Teams, Slack or as Widget on your
          self-help portal in few clicks.
        </p>
        <p className="font-section-normal-text-testimonials line-height-18">
          Customize the end-user experience by applying your company branding,
          logo etc.
        </p>
        <div className="icons_container_onboarding mb-3">
          <div className="icon_text">
            <img
              loading="lazy"
              class="slack_feature_icon_onboarding"
              src={FeatureSlack}
            ></img>
            <h6 className="font-section-small-text-medium-apps color-light-black mb-0 pt-1">
              Slack
            </h6>
          </div>

          <div className="icon_text">
            <img
              loading="lazy"
              class="teams_feature_icon_onboarding"
              src={FeatureTeams}
            ></img>
            <h6 className="font-section-small-text-medium-apps color-light-black mb-0 pt-1">
              Teams
            </h6>
          </div>
          <div className="icon_text">
            <img
              loading="lazy"
              class="chat_feature_icon_onboarding"
              src={FeatureChat}
            ></img>
            <h6 className="font-section-small-text-medium-apps color-light-black mb-0 pt-1">
              Chat Widget
            </h6>
          </div>
        </div>
        <img src={OnboardSlide4} alt="Go Live"></img>
      </>
    ),
  },
];
const tabsForOnBoardingImages = [
  OnboardSlide,
  OnboardSlide1,
  OnboardSlide2,
  OnboardSlide3,
  OnboardSlide4,
];
const mobileTablsTopData = {
  sections: [
    {
      tab: <span>Simplified UX</span>,
      section: <Section1 />,
    },
    {
      tab: <span>Built-in App Workflows</span>,
      section: <Section2 />,
    },
    {
      tab: <span> Bots & App Integrations Marketplace</span>,
      section: <Section3 />,
    },
    {
      tab: <span> Live Agent Handover</span>,
      section: <Section4 />,
    },
  ],
};
const slides = [
  {
    image: unlock_account,
    title: "Unlock Account",
    tabFor: "vertical-tab-one",
  },
  {
    image: password_resets,
    title: "Password Reset",
    tabFor: "vertical-tab-two",
  },
  {
    image: user_provisioning,
    title: "User Provisioning",
    tabFor: "vertical-tab-three",
  },
  {
    image: access_management,
    title: "Access Management",
    tabFor: "vertical-tab-four",
  },
  {
    image: asset_requests,
    title: "Asset Requests",
    tabFor: "vertical-tab-five",
  },
  {
    image: onboarding,
    title: "Onboarding",
    tabFor: "vertical-tab-six",
  },
  {
    image: offboarding,
    title: "Offboarding",
    tabFor: "vertical-tab-seven",
  },
];
function Section1() {
  return (
    <section
      className="features_img_left pl-10-tage landing_page trial_page_img bg_trial_page bg_landing_white  padding-top-20-landing padding-bottom-mobile-features-ibm"
      id="section1"
    >
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 feature_page_content_left">
            <p className="font-section-normal-text text-align-ibm-mobile">
              Workativ’s intuitive no-code chatbot builder helps you build
              simple to complex conversation in minutes.
            </p>
          </div>
          <div className="col-md-6 feature_page_img_right ">
            <img
              loading="lazy"
              src={section_one}
              alt="Simplified UX"
              className="tablet-image-size-landing"
            />
          </div>
        </div>
      </div>
    </section>
  );
}
function Section2() {
  return (
    <section
      className="features_img_left pl-10-tage landing_page trial_page_img bg_trial_page bg_landing_white padding-top-20-landing padding-bottom-mobile-features-ibm"
      id="section4"
    >
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 feature_page_content_left">
            <p className="font-section-normal-text text-align-ibm-mobile">
              AI Chatbot solutions such as Microsoft Power Virtual Agent uses
              3rd party tools like Zapier to integrate apps and execute app
              workflows or custom build app integrations which is time
              consuming, expensive and requires development efforts. But not
              with Workativ.
            </p>
            <p className="font-section-normal-text text-align-ibm-mobile">
              Worktaiv has a built-in app workflow builder to help you integrate
              chatbot with apps and automate app workflows in a few clicks. No
              coding required. Workativ is the only solution that helps you
              automate conversations and app workflows from a single tool thus
              helping you save time, costs, and efforts.
            </p>
          </div>
          <div className="col-md-6 feature_page_img_right ">
            <img
              loading="lazy"
              src={section_two}
              alt="Built-in App Workflows"
            />
          </div>
        </div>
      </div>
    </section>
  );
}
function Section3() {
  return (
    <section
      className="features_img_left pl-10-tage  landing_page trial_page_img bg_trial_page bg_landing_white padding-top-20-landing padding-bottom-mobile-features-ibm"
      id="section3"
    >
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 feature_page_content_left">
            <p className="font-section-normal-text text-align-ibm-mobile">
              No time to build? No problem. Workativ provides extensive
              collection of both pre-built chatbots and pre-built app
              integrations and app workflows. Just download pre-built Bots, 70+
              app integrations, and 600+ pre-built app workflows in a single
              click and go live in minutes. No coding.
            </p>
          </div>
          <div className="col-md-6 feature_page_img_right ">
            <img
              loading="lazy"
              src={section_three}
              alt="Bots & App Integrations Marketplace"
            />
          </div>
        </div>
      </div>
    </section>
  );
}
function Section4() {
  return (
    <section
      className="features_img_left pl-10-tage landing_page trial_page_img bg_trial_page bg_landing_white padding-top-20-landing padding-bottom-mobile-features-ibm"
      id="section4"
    >
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 feature_page_content_left">
            <p className="font-section-normal-text text-align-ibm-mobile">
              Let us imagine the user interacting with the chatbot had a query
              that was not pre-programmed in the bot, or the user needs
              additional information. Other tools like Microsoft Power Virtual
              Agent cannot do it without the inclusion of a separate piece of
              programming along with detailed instruction.
            </p>
            <p className="font-section-normal-text text-align-ibm-mobile">
              Workativ delivers seamless ‘agent handover’ of chatbot to live
              agents with complete context and conversation history in MS Teams
              so your agents can pick up right where the bot left, avoiding any
              user frustration – but most importantly solving end-user issues
              right away. Also workativ provides powerful controls for agents to
              manage the chat queue, user communication etc. that makes MS teams
              a powerful agent channel for your helpdesk.
            </p>
          </div>
          <div className="col-md-6 feature_page_img_right ">
            <img loading="lazy" src={section_four} alt="Live Agent Handover" />
          </div>
        </div>
      </div>
    </section>
  );
}
export const faqData = [
  {
    header: "Simple Easy to Use interface (EUS)",
    paragraph:
      "A user interface that is simple and easy to use in order to build simple or sophisticated chatbot with simple to complex integrations, approvals, agent handover and so on. Workativ is designed to help companies automate workplace support easily without the big bang approach of Microsoft Power Virtual Agent.",
    active: "active",
    uuid: "a",
  },
  {
    header: "Pre-built Chatbot and App Workflows",
    paragraph:
      "Extensive repository of pre-built chatbot templates, app workflow automations that helps you go live in mintutes.",
  },
  {
    header: "Flexible Digital Experience",
    paragraph:
      "Workativ Assistant can be integrated with popular digital channels to provide users with immediate workplace support from any location at any time. Users can instantly use their chosen app, such as Slack or MS Teams, to report workplace concerns, follow up, and get them fixed without the need for external assistance.",
  },
  {
    header: "Chatbot platform designed for workplace support automation",
    paragraph:
      "Upgrade your traditional Helpdesk with chatbot to provide users with instant workplace support from any location at any time. Workativ chatbot plafrom along with workflow automation is designed for hybrid workplace automation needs compared to a general purpose Microsoft Power Virtual Agent.",
  },
  {
    header: "Cost",
    paragraph:
      "Workativ is undoubtedly the most affordably priced chatbot starting with the free plan and paid plan with $299 flat for unlimited users unlike Microsoft Power Virtual Agent pricing based on sessions.",
  },
  {
    header: "Time to go live",
    paragraph:
      "Go live in hours as Workativ comes with prebuilt bots and app workflows. All you need to do is customize the chatbot conversations and connect with your applications and go live.",
  },
  {
    header: "No code platform",
    paragraph:
      "You don’t need to be a developer to build the chatbot as the platform is a completely no-code platform.",
  },
];
